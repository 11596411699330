import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import Modal from "../modal/quickbase-data-modal"

import "./hero.css"

const HeroQuickbaseDataCenter = () => {
  const { heroImage } = useStaticQuery(
    graphql`
      {
        heroImage: file(relativePath: { eq: "data-center.jpeg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1920
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  const heroImageData = getImage(heroImage)

  // Use like this:
  const bgImage = convertToBgImage(heroImageData)

  return (
    <>
      <div
        // Tag="div"
        // {...bgImage}
        // fluid={imageData}
        className="hero-banner hero-home-quickbase-data-center"
        // preserveStackingContext
        style={{ backgroundPosition: "top center", height: "600px" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="hero-custom-quickbase">
                <h3 className="page-title font-weight-medium mt-0 mb-4">
                  Manage Data Center Construction Projects From a Single
                  Platform
                </h3>
                <p className="mb-3">
                  We help businesses simplify workforce management using
                  Quickbase so they break ground on their next site with ease.
                </p>

                <Modal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeroQuickbaseDataCenter
