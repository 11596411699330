import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.min.css"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import "../sass/main.scss"
import "./font-face.css"
import Footer from "./footer/footer"
import Header from "./header/header-quickbase-data-center"
import "./layout.css"

//hooks
import useWindowSize from "./utils/useWindowsSize"

if (typeof window !== "undefined") {
  if (window.fbq != null) {
    window.fbq("track", "PageView")
  }
}

const LayoutDataCenter = ({ children, banner, location }) => {
  // const size = useWindowSize()
  useEffect(() => {
    // localStorage.setItem("title", "")
    if (location.pathname !== "/contact") localStorage.removeItem("title")
  })

  return (
    <>
      <Header location={location} />

      {banner}
      <main>{children}</main>
      <Footer />
    </>
  )
}

LayoutDataCenter.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutDataCenter
