import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout-quickbase-data-center"
import Seo from "../components/seo"
import Testimonials2 from "../components/swiper/testimonials-quickbase"
//components
import PartnerSwiper from "../components/swiper/partners.js"

import Modal from "../components/modal/quickbase-data-modal"

import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

import Hero from "../components/hero/hero-quickbase-data-center.js"

class QuickbaseDataCenterServicesPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        banner={<Hero />}
      >
        <Seo title={siteTitle} description={siteDescription} />
        <div id="main">
          <LazyLoadComponent>
            <section
              id="quandary-help-wrapper"
              className="quandary-help-wrapper mt-5"
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 text-center">
                    <h2 className="font-weight-medium">
                      ORGANIZATIONS <span className="text-green">TRUST</span>{" "}
                      QUANDARY CONSULTING GROUP
                    </h2>
                  </div>
                </div>
                <LazyLoadComponent>
                  <PartnerSwiper />
                </LazyLoadComponent>
              </div>
            </section>

            <section
              className="quickbase-support"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h1>YOUR ELITE PARTNER</h1>
                    <p>
                      Outsource the stress and frustration of managing complex
                      data center construction projects. We help get you more
                      out of Quickbase.
                    </p>
                    <div className="pt-5">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="quickbase-integrations">
              <div className="container quickbase-integrations-padding">
                <div className="row">
                  <div className="col-lg-12 text-center pt-5">
                    <p className="h3 text-white mt-4">
                      TEAM UP WITH THE LEADING QUICKBASE PARTNER
                    </p>
                    <p className="text-white">
                      Quickbase can be challenging to navigate. We’re here to
                      help you avoid roadblocks, slowdowns, and costly mistakes.
                    </p>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/coding-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      ENORMOUSLY RESPONSIVE
                    </p>
                    <p className="pt-2 text-white">
                      Get your questions answered and your concerns addressed in
                      hours (not days or weeks).
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/Drag-Icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      OWN YOUR CODE
                    </p>
                    <p className="pt-2 text-white">
                      We don’t hold your code hostage. We build the apps. You
                      control the code.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/dashboard-icons.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      MORE TIME
                    </p>
                    <p className="pt-2 text-white">
                      Delegate the heavy lifting to us and get more time to
                      focus on your core business concerns.
                    </p>
                  </div>
                </div>
                <div className="row pt-5">
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/phone-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      EFFORTLESS ONBOARDING
                    </p>
                    <p className="pt-2 text-white">
                      We train your team on new systems directly, ensuring
                      successful adoption.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/automate-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      DYNAMIC REPORTING
                    </p>
                    <p className="pt-2 text-white">
                      Get the exact reporting you envisioned with an accessible
                      dashboard for clear insights.
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-6 mt-5 text-center">
                    <StaticImage
                      placeholder="blurred"
                      className="img-fluid rounded"
                      src="../images/roi-icon.png"
                      alt="Workato Logo"
                      layout="constrained"
                      style={{ width: "25%" }}
                      loading="lazy"
                    />
                    <p className="my-4 text-white font-weight-bold">
                      ENHANCED ROI
                    </p>
                    <p className="pt-2 text-white">
                      See a higher return on your investment by utilizing more
                      Quickbase features.
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="what-to-expect" style={{ padding: "5rem 0" }}>
              <div className="container pb-5">
                <div className="row">
                  <div className="col-lg-12 col-md-8 mx-auto text-center">
                    <h2 className="font-weight-medium">
                      WORKFORCE MANAGEMENT BUILD FOR YOU
                    </h2>
                    <p className="mt-3">
                      As your Quickbase Elite Partner, we use our expertise and
                      experience to help you squeeze every bit of value out of
                      the platform.
                    </p>
                  </div>
                </div>
                <div className="row" style={{ padding: "65px 0px 0px 0px" }}>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      WORKFLOW MAPPING
                    </p>
                    <p className="my-3 pb-3">
                      We analyze your workflows FIRST
                      <br />
                      to find the root cause of your problems.
                    </p>
                  </div>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p className="text-green pt-3 font-weight-bold">
                      UNMATCHED EXPERIENCE
                    </p>
                    <p className="my-3 pb-3">
                      We’ve built a range of applications and automations <br />
                      from scratch for businesses large and small.
                    </p>
                  </div>
                  <div className="col mb-4 mx-5 what-to-expext-box text-center">
                    <p
                      className="text-green pt-3 font-weight-bold"
                      style={{ fontSize: "16.33px" }}
                    >
                      CONTINUOUS
                      <br />
                      IMPROVEMENT
                    </p>
                    <p className="my-3 pb-3">
                      We create a custom roadmap designed to streamline <br />
                      automation and provide the highest ROI.
                    </p>
                  </div>
                </div>
                <div className="col-lg-8 mx-auto text-center pt-5">
                  <Modal />
                </div>
              </div>
            </section>
            <LazyLoadComponent>
              <div
                className="text-center intro py-5 bg-gradient"
                style={{ padding: "5rem 0" }}
                id=""
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <h5 className="text-light-blue text-uppercase font-weight-bold m-0">
                        Reviews
                      </h5>
                      <h2 className="my-2 text-white mt-4">
                        What Our Clients Say
                      </h2>
                    </div>
                  </div>
                </div>
                <Testimonials2 />
              </div>
            </LazyLoadComponent>
            <section
              className="quickbase-support"
              style={{ padding: "5rem 0" }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto text-center">
                    <h1>DATA CENTER MANAGEMENT THAT WORKS SIMPLY</h1>
                    <p>
                      Data center construction projects are complex. Lots of
                      people. Lots of equipment. Lots of work. We make it easier
                      for you.
                    </p>
                    <div className="pt-5">
                      <Modal />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </LazyLoadComponent>
        </div>
      </Layout>
    )
  }
}

export default QuickbaseDataCenterServicesPage

export const QuickbaseDataCenterServicesPagePageQuery = graphql`
  query QuickbaseDataCenterServicesPagePageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
