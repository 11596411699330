import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Swiper from "react-id-swiper"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import "swiper/css/swiper.css"
import { Navigation, Pagination } from "swiper/js/swiper.esm"
import { reviewData } from "../../components/reviewData"
import "./testi-new.scss"

const ContactModal = props => {
  const { buttonLabel, item } = props
  const [modal, setModal] = useState(false)
  const toggle = () => setModal(!modal)
  // console.log(item)

  return (
    <>
      <Button
        aria-label="btn"
        style={{
          background: "none",
          border: "none",
          padding: 0,
          textTransform: "capitalize",
        }}
        onClick={toggle}
      >
        <strong>{buttonLabel}</strong>
      </Button>

      <Modal
        centered={true}
        isOpen={modal}
        toggle={toggle}
        className="contactNewTesti"
      >
        <ModalHeader toggle={toggle}>
          <h4 className="mb-0">{item.title}</h4>
        </ModalHeader>
        <ModalBody>
          {item.review}
          <br />
          <br />
          <h5 className="title">{item.reviewer}</h5>
          <h5 className="title">{item.position}</h5>
        </ModalBody>
      </Modal>
    </>
  )
}

function Testimonial() {
  // console.log(contactTestimonial)
  const params = {
    modules: [Pagination, Navigation],
    slidesPerView: 1,
    spaceBetween: 30,
    breakpoints: {
      280: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
    containerClass: "swiper-container pb-5",
    nested: true,

    autoplay: false,
    // {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  }

  return (
    <section className="new-testimonial">
      <ScrollAnimation
        animateIn="fadeIn"
        initiallyVisible={false}
        animateOnce={true}
      >
        <div>
          <div>
            <div className="container">
              <Swiper {...params}>
                {reviewData.map((item, idx) => {
                  const limitReview = item.review.substring(0, 160)

                  return (
                    <div key={idx} className="swiper-slide">
                      <div className="testi-item h-100">
                        <div className="testi-item-inner">
                          {/* <div className="testimonial-header">
                            <h4>{item.title}</h4>
                          </div> */}
                          <div className="testimonial-content newContent">
                            <p>
                              {limitReview}
                              {limitReview.length >= 160 ? (
                                <>
                                  <br />
                                  <span>
                                    ...
                                    <ContactModal
                                      item={item}
                                      buttonLabel="Read More"
                                    />
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <div className="testimonial-holder">
                            <div className="testimonial-meta">
                              <h5 className="title mb-0">{item.reviewer}</h5>
                              <p
                                className="company"
                                style={{ color: "#8a49a8", fontSize: "13px" }}
                              >
                                {item.company}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </ScrollAnimation>
    </section>
  )
}

export default Testimonial
